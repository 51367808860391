<template>
    <b-row>
         <b-col lg="12" class="mt-3">
            <!-- <b-button variant="success" size="sm" class="mr-2" v-b-modal.oyuCatModal>Жана</b-button>
            <b-button variant="info" v-if="selectedRows.length==1" size="sm" @click="updateRecord" class="mr-2">Өңдеу</b-button>
            <b-button variant="danger" v-if="selectedRows.length>0" size="sm" class="mr-2" @click="deleteRecord">Жою</b-button> -->
         </b-col>

         <b-col lg="4">
            <b-form-group
           
            label-cols-sm="1"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-2 mt-3"
            >
            <b-input-group size="sm">
                <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                :placeholder="$i18n.locale()=='kz' ? 'Іздеу' : 'Поиск'"
                ></b-form-input>
                
            </b-input-group>
            </b-form-group>
        </b-col>
        
        <b-col lg="3" class="pt-3">
            <select class="form-control-sm w-100" v-model="status" @change="sysChange">
                <option value="">{{$t("qaj.org.allStatus")}}</option>
                <option value="CHECKING">{{$t("qaj.org.checking")}}</option>
                <option value="PASSED">{{$t("qaj.org.passed")}}</option>
                <option value="APPROVED">{{$t("qaj.org.approved")}}</option>
                <option value="PENDING">{{$t("qaj.org.pending")}}</option>
                <option value="SIGNED">{{$t("qaj.org.signed")}}</option>
                <option value="DECLINE">{{$t("qaj.org.decline")}}</option>
            </select>
        </b-col>
        <b-col lg="3" class="pt-3">
            <select class="form-control-sm w-100" v-model="orgType" @change="sysChange">
                <option value="">{{$t("qaj.org.all") +' ' + $t("qaj.org.type").toLowerCase()}}</option>
                <option value="ИП">ИП</option>
                <option value="ТОО">ТОО</option>
                <option value="АО">АО</option>
                <option value="КГУ">КГУ</option>
                <option value="ГУ">ГУ</option>
                <option value="ГКП на ПХВ">ГКП на ПХВ</option>
                <option value="НАО">НАО</option>
                <option value="РГП">РГП</option>
                <option value="РГП на ПХВ">РГП на ПХВ</option>
                <option value="РГКП">РГКП</option>
            </select>
        </b-col>

        <b-col lg="2" class="pt-3 text-right">
            <strong>
            {{$i18n.locale()=='kz' ? 'Жалпы саны:' : 'Общее число'}}
            </strong> {{totalRows}}  
        </b-col>
        <b-col lg="12">
              <b-table 
                ref="orgRef"
                striped hover selectable
                :items="recordProvider" 
                :fields="fields"
                :busy.sync="isBusy"
                :current-page="currentPage"
                :per-page="perPage"
                table-variant='light'
                :filter="filter"
                :select-mode="selectMode"
                
               
                @row-selected="onRecordSelected"
                >
               
                <template v-slot:table-busy>
                    <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Жүктелуде...</strong>
                    </div>
                </template>
               
                <template #head(name)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(status)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(bin)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(companyType)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template #head(createdAt)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>
                <template v-slot:cell(createdAt)="row">
                    {{row.item.createdAt}}
                    <br>
                    <b-badge  variant="danger" v-if="row.item.contractNumber.length>0">
                        {{  row.item.contractNumber}}     
                    </b-badge>
                </template>
                <template v-slot:cell(name)="row">
                    <span v-html="row.item.name"></span>
                </template>
                <template v-slot:cell(status)="row">
                    <b-badge v-if="row.item.status=='CHECKING'" variant="secondary" v-html="$t('qaj.org.checking')"></b-badge>
                    <b-badge v-if="row.item.status=='PASSED'" variant="primary" v-html="$t('qaj.org.passed')" ></b-badge>
                    <b-badge v-if="row.item.status=='APPROVED'" variant="info" v-html="$t('qaj.org.approved')"></b-badge>
                    <b-badge v-if="row.item.status=='PENDING'" variant="warning" v-html="$t('qaj.org.pending')"></b-badge>
                    <b-badge v-if="row.item.status=='SIGNED'" variant="success" v-html="$t('qaj.org.signed')"></b-badge>
                    <b-badge v-if="row.item.status=='DECLINE'" variant="danger" v-html="$t('qaj.org.decline')"></b-badge>
                </template>
                <template #head(actions)="data">
                    <span>{{ $t(data.label) }}</span>
                </template>

                <template v-slot:cell(actions)="row">
                    <b-btn variant="secondary" size="sm" @click="downloadContract(row.item.documentId, row.item.bin)" class="mr-2">{{$t("qaj.org.contract")}}</b-btn>
                    <b-btn variant="primary" size="sm" @click="downloadFile(row.item.documentId)">{{ $t("qaj.org.downloadDoc").toUpperCase() }}</b-btn>

                    <b-btn variant="success" 
                    v-if="row.item.status == 'CHECKING' || row.item.status=='DECLINE' "
                    class="ml-1" size="sm" @click="changeStatus('PASSED',row.item.documentId,row.item.organizationId)" >{{ $t("qaj.org.accept").toUpperCase() }}</b-btn>

                    <b-btn variant="success" v-if="row.item.status == 'PASSED'"
                    class="ml-1" size="sm" @click="operatorSign(row.item.documentId,row.item.organizationId)" >{{ $t("qaj.org.sign").toUpperCase() }}</b-btn>

                    <b-btn variant="warning" v-if="row.item.status == 'APPROVED'"
                    class="ml-1" size="sm" @click="changeStatus('CHECKING',row.item.documentId,row.item.organizationId)" >{{ $t("qaj.org.removeSign").toUpperCase() }}</b-btn>

                    <b-btn variant="danger"  v-if="row.item.status != 'APPROVED'"
                        @click="showDeclineModal(row.item.documentId,row.item.organizationId)" size="sm" class="ml-1">
                        {{ $t("qaj.org.declined").toUpperCase() }}
                    </b-btn>
                    <br v-if="row.item.comments.length>0 && (row.item.status=='DECLINE' || row.item.status=='PENDING')" >
                    <b-alert variant="warning" show class="mt-2" v-if="row.item.comments.length>0 && (row.item.status=='DECLINE' || row.item.status=='PENDING')" >
                        {{  row.item.comments }}
                    </b-alert>
                </template>
                
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
               
                size="sm"
                class="my-0"
            ></b-pagination>

            <b-modal id="declineModal" 
                :title="$t('qaj.org.decReason')" 
                hide-footer size="md" 
                @hidden="clearForm();sysChange()">
                <b-form v-on:submit.prevent="declineDocument">
                    <b-form-row class="mb-3">
                        <b-col sm="auto" lg="12">
                            <label for="reason">{{ $t('qaj.org.decQuestion') }}</label>
                            <select required id="reason" 
                                class="form-control" 
                                v-model="decForm.declineType">
                                <option value="">
                                    {{ $i18n.locale()=='kz' ? 'Таңдаңыз...' : 'Выберите...' }}
                                </option>
                                <!-- 'INVALID_NAME',
	                            'OTHERS' -->
                                <option :value="'INVALID_NAME'">
                                    {{$i18n.locale()=='kz' ?  'Ұйымның атауы дұрыс емес' : 'Неправильное наименование огранизации' }}
                                </option>
                                <option :value="'FILL_DOC'">
                                    {{$t('qaj.org.fillDoc') }}
                                </option>
                                <option :value="'OTHERS'">
                                    {{$i18n.locale()=='kz' ?  'Басқа' : 'Другое' }}
                                </option>
                            </select>
                        </b-col>
                        <b-col sm="auto" lg="12" v-if="decForm.declineType=='OTHERS' 
                        || decForm.declineType=='FILL_DOC'">
                            <label for="desciption">{{ $t('qaj.org.description') }}</label>
                            <textarea required id="description" class="form-control" v-model="decForm.comments"></textarea>
                        </b-col>

                        <b-col lg="12">
                            <b-row class="pt-2">
                                <b-col lg="6">
                                    <b-button size="sm" block variant="primary" @click="clearForm" class="mr-2" >{{$t("qaj.reg.back")}}</b-button>
                                </b-col>
                                <b-col lg="6">
                                    <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.org.declined")}}</b-button>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-form-row>
                </b-form>
            </b-modal>
            
            <DepModal :orgId="orgId" :bin="bin" :orgName="orgName"></DepModal>
            
        </b-col>
    </b-row>
</template>
<script>
import axios from 'axios';
import {apiDomain,getHeader,downloadHeader} from "@/config/config";
import CatForm from "@/components/oyu/CatForm"
import DepModal from "@/components/law/DepModal"
import vue from 'vue';
import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext"
export default {
    name:"Organization",
    components:{CatForm,DepModal},
    data(){
        return {
            orgId:0,
            bin:"",
            orgName:"",
            fields: [
                
                {
                    key: 'name',
                    label: 'qaj.org.name',
                    thStyle: { width: "20%" } ,
                    variant:"primary"
                },
                {
                    key: 'bin',
                    label: 'qaj.org.bin',
                  
                },
                {
                    key: 'status',
                    label: 'qaj.org.status',
                },
               
                {
                    thStyle: { width: "30%" } ,
                    key:"actions",
                    label:"qaj.org.actions",
                    variant:'secondary'
                },
                {
                    key:"createdAt",
                    label: 'qaj.org.created',
                    variant:"success",
                    sortable:true
                },
                {
                    key:"companyType",
                    label: 'qaj.org.type',
                },
               
            ],
            isBusy:false,
            totalRows:0,
            currentPage: 1,
            perPage: 20,
            tableVariant:'light',
            filter:"",
            selectMode:'single',
            selectedRows:[],

            catId:0,
            status:"",
            orgType:"",

            decForm:{
                status:"DECLINE",
                declineType:"",
                comments:"",
                docId:"",
                orgId:""
            }
        }
    },
    methods:{
        addDep(row){

            
            this.orgId = row.organizationId;
            this.bin = row.bin;
            this.orgName = row.name;
            
            this.$bvModal.show("depModal");
        },
        showDeclineModal(documentId,orgId){
            if(documentId && documentId.length>0){
                this.decForm.docId=documentId;
                this.decForm.orgId = orgId;
                this.$bvModal.show('declineModal')
            }
        },
        declineDocument(){
            //alert(JSON.stringify(this.decForm));
            let warn = confirm("Are you sure ? ");
            if(warn){
                if(this.decForm.docId.length>0){
                    if(this.decForm.declineType=='FILL_DOC') {
                        this.decForm.status='PENDING';
                        this.decForm.declineType="OTHERS";
                    }
                   
                    axios.post(apiDomain+'/law/changestatus',this.decForm,{headers:getHeader()})
                    .then(()=>{
                        this.sysChange()
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        })
                    }) 
                }
            }
        },
        clearForm(){
            this.decForm={
                status:"DECLINE",
                declineType:"",
                comments:"",
                docId:"",
            }
        },
        contractBlob(documentId) {
            try {
                return new Promise((resolve, reject) => {
                axios.post(apiDomain + "/law/downloadcontract", { "documentId": documentId }, { responseType: "blob", headers: getHeader() })
                .then(response => response.data)
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = function() {
                        const base64Data = reader.result;
                        resolve(base64Data); // Resolve the promise with the Base64 data
                    };
                })
                .catch(err => {
                    reject(err); // Reject the promise if there's an error
                });
            });

               
            } catch (error) {
                // Handle errors
                console.error("Error downloading file:", error);
            }
        },
        async signDocument(dataBase64) {
            dataBase64=dataBase64.split(',')[1];
            let NCALaClient = new NCALayerClientExtension();
            try {
                await NCALaClient.connect();
            } catch (error) {
               alert(error);
               return null;
            }
            try {
                
                
                let signature = await NCALaClient.sign('cms', {}, dataBase64, null, this.$i18n.locale, true);
                return signature;
                // this.signature = await NCALaClient.createCAdESFromBase64('PKCS12', this.resumeBlob, 'SIGNATURE', false)
                
            } catch (error) {
                alert(error)
                
            }
        },
        async operatorSign(documentId,orgId){
            if(documentId.length>0){
                let base64 = await this.contractBlob(documentId);
                let sig = await this.signDocument(base64);
                if(sig!=null && sig.length>0){
                    axios.post(apiDomain+'/law/signcontract1',{"docId":documentId,body:sig,"orgId":orgId,"dataBase64":base64.split(",")[1]},{headers:getHeader()})
                    .then(()=>{
                        this.sysChange()
                    })
                    .catch(error => {
                        //console.log(error.message)
                        if(error.response.status==789){
                            this.$bvToast.toast(vue.i18n.translate("qaj.org.noSign"), {
                                title: 'ERROR',
                                variant:"danger",
                                autoHideDelay: 5000
                            })
                        }else{
                            this.$bvToast.toast(error.message, {
                                title: 'Кате',
                                variant:"danger",
                                autoHideDelay: 5000
                            })
                        }
                        
                    }) 
                }
                
            }
        },
        changeStatus(status,documentId,orgId){
            
            let warn = confirm("Are you sure ? ");
            if(warn){
                if(documentId.length>0){
                    axios.post(apiDomain+'/law/changestatus',{"docId":documentId,"status":status,"orgId":orgId},{headers:getHeader()})
                    .then(()=>{
                        this.sysChange()
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        })
                    }) 
                }
            }
        },
        downloadFile(documentId) {
            try {
                axios.post(apiDomain + "/law/downloadfile", { "documentId": documentId }, { responseType: "blob", headers: getHeader() })
                .then((response) => {
                    // Get the content type from the response headers
                    const contentType = response.headers['content-type'];
                    // Define a mapping of common content types to file extensions
                    const contentTypeToExtension = {
                        'application/pdf': 'pdf',
                        'application/zip': 'zip',
                        'application/rar': 'rar',
                        'image/jpeg': 'jpg',
                        'image/png': 'png',
                        'image/jpg': 'jpg',
                        'image/gif': 'gif',
                        //Add more mappings for other content types as needed
                    };
                    const contentDispositionHeader = response.headers['Content-Disposition'];                    
                    const defaultExtension = 'file'; 
                    const extension = contentTypeToExtension[contentType] || defaultExtension;
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `File-${documentId}.${extension}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch(err => {
                    console.log(err);
                });

               
            } catch (error) {
                // Handle errors
                console.error("error downloading file:", error);
            }
        },
        downloadContract(documentId, bin) {
            try {
                axios.post(apiDomain + "/law/downloadcontract", { "documentId": documentId, "bin": bin }, { responseType: "blob", headers: getHeader() })
                .then((response) => {
                    // Get the content type from the response headers
                    const contentType = response.headers['content-type'];
                    // Define a mapping of common content types to file extensions
                    const contentTypeToExtension = {
                        'application/pdf': 'pdf',
                        'application/zip': 'zip',
                        'application/rar': 'rar',
                        'image/jpeg': 'jpg',
                        'image/png': 'png',
                        'image/jpg': 'jpg',
                        'image/gif': 'gif',
                        //Add more mappings for other content types as needed
                    };
                    const contentDispositionHeader = response.headers['Content-Disposition'];                    
                    const defaultExtension = 'Договор'; 
                    const extension = contentTypeToExtension[contentType] || defaultExtension;
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `Договор-${bin}.${extension}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch(err => {
                    console.log(err);
                });

               
            } catch (error) {
                // Handle errors
                console.error("Error downloading file:", error);
            }
        },

        sysChange(){
            this.$refs.orgRef.refresh();  
        },
       
        deleteRecord(){
            let warn = confirm("Сіз сенімдісіз бе ?");
            if(warn){
            
                if(this.selectedRows.length>0){
                    axios.post(apiDomain+'/oyu/cat/delete',this.selectedRows,{headers:getHeader()})
                    .then(()=>{

                        this.sysChange()
                    })
                    .catch(error => {
                        //console.log(error.message)
                        this.$bvToast.toast(error.message, {
                            title: 'Кате',
                            autoHideDelay: 5000
                        })
                    }) 
                }
            }
        
        },
        updateRecord(){
          if(this.selectedRows.length==1){
            let Id = this.selectedRows[0].catId;
            if(Id>0){
                this.$refs.catForm.updateCategory(Id)
            }
          }
        },
    
       
        onRecordSelected(items){
            this.selectedRows=items;
        },
        
        recordProvider(ctx){
            ctx.status = this.status;
            ctx.orgType=this.orgType;
            this.isBusy = false
            let promise = axios.post(apiDomain+'/law/organizations',ctx,{headers:getHeader()});
                return promise.then((response) => {
                const result = response.data;
                this.isBusy = false
                this.totalRows=result.recordCount;
                //alert(JSON.stringify(result));
                return(result.items)
            }).catch(error => {
                this.isBusy = false
                return []
            })
        }
    },
    mounted() {
      const toast = this.$route.query.toast;
      const now = new Date();
      const formattedDateTime = now.toLocaleString();
      if(toast === 'success'){
        this.$bvToast.toast(`Последняя успешная авторизация: ${formattedDateTime}`, {
          title: 'Информация об авторизациях',
          variant: 'success',
          solid: true
        });
      }else if (toast ==='error'){
        this.$bvToast.toast('Failed to load user information', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
      }
    },
    created(){
    
    }   
}
</script>
