<template>
    <b-row>
        <b-col lg="12">
           
            <b-row>
                <b-col lg="5" class="mb-3">
                    <b-button variant="outline-success" @click="showDocModal">
                        {{ $t("qaj.acc.newDocument") }}
                    </b-button>
                    <b-button variant="outline-info ml-2" @click="rootOrgDocs()">
                        <svg fill="#355f5d" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 472.615 472.615" xml:space="preserve" transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M236.308,0C168.221,0,103.915,29.376,59.324,79.742V0.357H40.048v116.907h116.907V97.989H69.176 c41.066-49.558,102.195-78.713,167.131-78.713c119.674,0,217.032,97.358,217.032,217.032S355.982,453.34,236.308,453.34 S19.276,355.982,19.276,236.308c0-18.081,2.231-36.071,6.635-53.47l-18.692-4.725C2.429,197.055,0,216.636,0,236.308 c0,130.3,106.008,236.308,236.308,236.308s236.308-106.008,236.308-236.308S366.608,0,236.308,0z"></path> </g> </g> </g></svg>
                    </b-button>
                </b-col>
                <b-col lg="7"></b-col>
                <!-- begining of the document LIST -->
                <b-col lg="12">
                    <div class="w-100">
                        <div class="float-left mr-1 mb-2" v-for="(d,di) in docs" :key="di">
                            <b-card
                                :title="$t('qaj.acc.docName')"
                                body-class="card-body"
                            >
                                <b-card-text>
                                    {{ $t('qaj.acc.createdDate')+': '+d.createdAt }}<br>
                                    <span v-if="d.status=='SIGNED'">{{ $t('qaj.acc.contractNumber')+': '+d.contractNumber }}</span>
                                    <hr>
                                    <span><strong>{{ $t('qaj.acc.status')}}</strong></span>: 
                                    <b-badge variant="secondary" v-if="d.status=='CHECKING'">
                                        {{ $t('qaj.acc.checkingText')}}
                                    </b-badge>
                                    <b-badge v-if="d.status=='PASSED'" variant="primary">{{ $t('qaj.org.passed')}}</b-badge>
                                    <b-badge v-if="d.status=='APPROVED'" variant="info">{{ $t('qaj.acc.approvedText')}}</b-badge>
                                    <b-badge v-if="d.status=='PENDING'" variant="warning">{{ $t('qaj.acc.pendingText')}}<br> </b-badge>
                                    <b-badge v-if="d.status=='SIGNED'" variant="success">{{ $t('qaj.org.signed')}}<br> </b-badge>
                                    <b-badge v-if="d.status=='DECLINE'"  variant="warning">{{ $t('qaj.org.decline')}}<br> </b-badge>
                                    
                                    <div class="w-100 mb-3"></div>
                                    <b-alert show variant="warning" v-if="d.comments.length>0 && d.status=='PENDING' ">
                                        <span >{{ d.comments }}</span>
                                    </b-alert>

                                    <b-alert show v-if="d.status=='SIGNED'" variant="success" v-html="$t('qaj.acc.signedText')"> </b-alert>

                                    <b-alert v-if="d.status=='DECLINE'" show variant="warning">
                                       
                                        <span style="text-transform: uppercase;" 
                                        v-if="d.declineType=='INVALID_NAME'">{{ $t('qaj.acc.correctCom') }}</span>

                                        <span v-if="d.declineType=='OTHERS'">{{ d.comments }}</span>
                                    </b-alert>
                                </b-card-text>
                                <b-btn variant="secondary" size="sm" @click="downloadContract(d.docId, d.bin)" class="mr-2">{{$t("qaj.org.contract")}}</b-btn>
                                <b-btn variant="primary" size="sm" @click="downloadFile(d.docId)">{{ $t("qaj.org.downloadDoc").toUpperCase() }}</b-btn>
                                <hr>
                                
                                <b-button size="sm" variant="warning" href="#"  
                                @click="reFill(d.docId,d.assetId,d.assetName)"
                                v-if="d.status=='PENDING' 
                                || (d.declineType=='OTHERS' && d.status=='DECLINE')">
                                    {{ $t('qaj.acc.pendingBtnText') }}
                                </b-button>
                                <b-button size="sm" variant="success" href="#"  @click="userSign(d.docId)" v-if="d.status=='APPROVED'">
                                    {{ $t('qaj.org.sign') }}
                                </b-button>
                                <b-button size="sm" variant="danger" href="#"  @click="correctName(d.orgId,d.docId)" v-if="d.status=='DECLINE' 
                                && d.declineType=='INVALID_NAME'">
                                    {{ $t('qaj.acc.correctBtn') }}
                                </b-button>
                            </b-card>
                        </div>
                    </div>
                </b-col>
                <DocModal :rootOrgDocs="rootOrgDocs" ></DocModal>
                <b-modal id="updateModal" 
                    :title="$t('qaj.acc.correctBtn')" 
                    hide-footer size="md" 
                    
                    @hidden="clearForm();sysChange()">
                    <b-form v-on:submit.prevent="updateOrgName">
                        <b-form-row class="mb-3">
                            
                            <b-col sm="auto" lg="12">
                                <label for="orgName">{{ $t('qaj.acc.orgName') }}</label>
                                <b-input required id="orgName" class="form-control" v-model="uForm.name"></b-input>
                            </b-col>

                            <b-col lg="12">
                                <b-row class="pt-2">
                                    <b-col lg="6">
                                        <b-button size="sm" block variant="primary" @click="clearForm" class="mr-2" >{{$t("qaj.reg.back")}}</b-button>
                                    </b-col>
                                    <b-col lg="6">
                                        <b-button size="sm" block variant="success" type="submit" >{{$t("qaj.acc.correctBtn")}}</b-button>
                                    </b-col>
                                </b-row>
                            </b-col>

                        </b-form-row>
                    </b-form>
                </b-modal>
            </b-row>
        </b-col>
        
    </b-row>
</template>
<script>
    import axios from 'axios';
    import {apiDomain,getHeader} from "@/config/config";
    import DocModal from "@/components/law/DocModal"
    import  NCALayerClientExtension  from "@/helpers/ncalayer-client-ext"
    import vue from 'vue';
    export default {
        name:"UserAccount",
        components:{DocModal},
        data(){
            return {
                
                docs:[],
                uForm:{
                    orgId:"",
                    name:"",
                    documentId:"",
                    bin:"",
                },
                rootOrganizaion:{}
            }
        },
        methods:{
            downloadFile(documentId) {
                try {
                    axios.post(apiDomain + "/law/downloadfile", { "documentId": documentId }, { responseType: "blob", headers: getHeader() })
                    .then((response) => {
                        // Get the content type from the response headers
                        const contentType = response.headers['content-type'];
                        // Define a mapping of common content types to file extensions
                        const contentTypeToExtension = {
                            'application/pdf': 'pdf',
                            'application/zip': 'zip',
                            'application/rar': 'rar',
                            'image/jpeg': 'jpg',
                            'image/png': 'png',
                            'image/jpg': 'jpg',
                            'image/gif': 'gif',
                            //Add more mappings for other content types as needed
                        };
                        const contentDispositionHeader = response.headers['Content-Disposition'];
                        const defaultExtension = 'file';
                        const extension = contentTypeToExtension[contentType] || defaultExtension;
                        const href = URL.createObjectURL(response.data);
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `Документы-${documentId}.${extension}`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    })
                    .catch(err => {
                        console.log(err);
                    });


                } catch (error) {
                    // Handle errors
                    console.error("error downloading file:", error);
                }
            },
            downloadContract(documentId, bin) {
                try {
                    axios.post(apiDomain + "/law/downloadcontract", { "documentId": documentId, "bin": bin }, { responseType: "blob", headers: getHeader() })
                    .then((response) => {
                        // Get the content type from the response headers
                        const contentType = response.headers['content-type'];
                        // Define a mapping of common content types to file extensions
                        const contentTypeToExtension = {
                            'application/pdf': 'pdf',
                            'application/zip': 'zip',
                            'application/rar': 'rar',
                            'image/jpeg': 'jpg',
                            'image/png': 'png',
                            'image/jpg': 'jpg',
                            'image/gif': 'gif',
                            //Add more mappings for other content types as needed
                        };
                        const contentDispositionHeader = response.headers['Content-Disposition'];
                        const defaultExtension = 'file';
                        const extension = contentTypeToExtension[contentType] || defaultExtension;
                        const href = URL.createObjectURL(response.data);
                        const link = document.createElement('a');
                        const fileName =  `Договор.${extension}`;
                        link.href = href;
                        link.setAttribute('download', fileName);
                        console.log(fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    })
                    .catch(err => {
                        console.log(err);
                    });


                } catch (error) {
                    // Handle errors
                    console.error("Error downloading file:", error);
                }
            },
            showDocModal(){
                this.$bvModal.show("docModal");
            },
            rootOrg(){
                //alert("me is called");
                axios.post(apiDomain+'/law/rootorg',{},{headers:getHeader()})
                .then(response=>{
                    this.rootOrganizaion=response.data;
                })
                .catch(error => {
                    alert(error.message);
                })
            },
            contractBlob(documentId) {
                try {
                    return new Promise((resolve, reject) => {
                        axios.post(apiDomain + "/law/downloadcontract", { "documentId": documentId }, { responseType: "blob", headers: getHeader() })
                        .then(response => response.data)
                        .then(blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = function() {
                                const base64Data = reader.result;
                                resolve(base64Data); // Resolve the promise with the Base64 data
                            };
                        })
                        .catch(err => {
                            reject(err); // Reject the promise if there's an error
                        });
                    });


                } catch (error) {
                    // Handle errors
                    console.error("Error downloading file:", error);
                }
            },
            async signDocument(dataBase64) {
                dataBase64=dataBase64.split(',')[1];
                let NCALaClient = new NCALayerClientExtension();
                try {
                    await NCALaClient.connect();
                } catch (error) {
                    alert(error);
                    return
                }
                try {
                    let signature = await NCALaClient.sign('cms', {}, dataBase64, null, this.$i18n.locale, true);
                    return signature;
                    // this.signature = await NCALaClient.createCAdESFromBase64('PKCS12', this.resumeBlob, 'SIGNATURE', false)

                } catch (error) {
                    alert(error)

                }
            },
            async userSign(documentId){
                if(documentId.length>0){
                    let base64 = await this.contractBlob(documentId);
                    let sig = await this.signDocument(base64);

                    if(sig!=null && sig.length>10){
                        axios.post(apiDomain+'/law/signcontract1',
                        {"docId":documentId,body:sig,"orgId":this.rootOrganizaion.organizationId,"dataBase64":base64.split(",")[1]},{headers:getHeader()})
                        .then(()=>{
                            this.rootOrg();
                            this.rootOrgDocs();
                        })
                        .catch(error => {
                            //console.log(error.message)
                            if(error.response.status==789){
                                this.$bvToast.toast(vue.i18n.translate("qaj.org.noSign"), {
                                    title: 'ERROR',
                                    variant:"danger",
                                    autoHideDelay: 5000
                                })
                            }else{
                                this.$bvToast.toast(error.message, {
                                    title: 'Кате',
                                    variant:"danger",
                                    autoHideDelay: 5000
                                })
                            }
                        })
                    }

                }
            },
            changeStatus(status,documentId){
                let warn = confirm("Are you sure ? ");
                if(warn){
                    if(documentId.length>0){
                        axios.post(apiDomain+'/law/changestatus',{"docId":documentId,"status":status},{headers:getHeader()})
                        .then(()=>{
                            this.rootOrg()
                        })
                        .catch(error => {
                            //console.log(error.message)
                            this.$bvToast.toast(error.message, {
                                title: 'Кате',
                                autoHideDelay: 5000
                            })
                        })
                    }
                }
            },
            reFill(docId,assetId,assetName){
                this.rootOrganizaion.documentId=docId;
                this.rootOrganizaion.assetId=assetId;
                this.rootOrganizaion.assetName=assetName
                //alert(`docId ${docId} assetId ${assetId} assetname ${assetName}`);
                this.showDocModal();
            },
            clearForm(){
                this.uForm={
                    organizationId:"",
                    name:"",
                    documentId:""
                };
                this.$bvModal.hide("updateModal");
            },
            correctName(orgId,docId){
                if(orgId.length>0 && docId.length>0){
                    this.uForm.organizationId=orgId;
                    this.uForm.documentId=docId;
                    this.uForm.name=this.rootOrganizaion.name;
                    this.$bvModal.show("updateModal");
                }
            },
            updateOrgName(){
                axios.post(apiDomain+'/law/updateorgname',this.uForm,{headers:getHeader()})
                .then(response=>{
                    if(response.data=='success'){
                        this.rootOrganizaion.name=this.uForm.name;
                        this.clearForm();
                        this.rootOrgDocs();
                    }
                })
                .catch(error => {
                    alert(error.message);
                })
            },


            rootOrgDocs(){
                axios.post(apiDomain+'/law/rootdocs',{},{headers:getHeader()})
                .then(response=>{
                    this.docs=response.data;

                })
                .catch(error => {
                    alert(error.message);
                })
            }
        },
        mounted() {
          const toast = this.$route.query.toast;
          const now = new Date();
          const formattedDateTime = now.toLocaleString();
          if(toast === 'success'){
            this.$bvToast.toast(`Последняя успешная авторизация: ${formattedDateTime}`, {
              title: 'Информация об авторизациях',
              variant: 'success',
              solid: true
            });
          }else if (toast ==='error'){
            this.$bvToast.toast('Failed to load user information', {
              title: 'Error',
              variant: 'danger',
              solid: true
            });
          }
        },
      created(){
            this.rootOrg();
            this.rootOrgDocs();
        }
    }
</script>
<style scoped>
    .card-body{
        height:450px;
        width:420px;
    }
</style>